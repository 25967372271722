import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { URITemplate } from '@app/api/services/uri-template';
import {
  ApiResponse,
  CardOrder,
  Customer,
  Invoice,
  LoadOrder,
  Multiplier,
  ScheduledLoadOrder,
  UnloadOrder,
  Voucher,
  VoucherOwner,
} from '@givve/ui-kit/models';
import { Observable, of } from 'rxjs';
import { RegexRule } from '../stores/global-search.store';

@Injectable({
  providedIn: 'root',
})
export class GlobalSearchService {
  private httpClient = inject(HttpClient);

  searchUri = new URITemplate(`{+api_base}/admin/search/{collection}`);
  filterUri = new URITemplate(`{+api_base}/admin/search/{collection}`);

  loadCustomers(
    params: Params,
    regexRules: RegexRule[],
    excludedRegexValidators: RegexRule[]
  ): Observable<ApiResponse<Customer[]> | null> {
    const matchsExcludeRegex = this.checkIfExcludedRegexMatches(params.query, excludedRegexValidators);
    if (matchsExcludeRegex) return of(null);

    const regexRule = this.checkIfRegexMatches(params.query, regexRules);
    const httpParams = this.getHttpParams(regexRule!, params);
    const httpUri = this.getHttpUri('customers');

    return this.httpClient.get<ApiResponse<Customer[]>>(httpUri, { params: httpParams });
  }

  loadMultipliers(
    params: Params,
    regexRules: RegexRule[],
    excludedRegexValidators: RegexRule[]
  ): Observable<ApiResponse<Multiplier[]> | null> {
    const matchsExcludeRegex = this.checkIfExcludedRegexMatches(params.query, excludedRegexValidators);
    if (matchsExcludeRegex) return of(null);

    const regexRule = this.checkIfRegexMatches(params.query, regexRules);
    const httpParams = this.getHttpParams(regexRule!, params);
    const httpUri = this.getHttpUri('multipliers');

    return this.httpClient.get<ApiResponse<Multiplier[]>>(httpUri, { params: httpParams });
  }

  loadVouchers(
    params: Params,
    regexRules: RegexRule[],
    excludedRegexValidators: RegexRule[]
  ): Observable<ApiResponse<Voucher[]> | null> {
    const matchsExcludeRegex = this.checkIfExcludedRegexMatches(params.query, excludedRegexValidators);
    if (matchsExcludeRegex) return of(null);

    const regexRule = this.checkIfRegexMatches(params.query, regexRules);
    const httpParams = this.getHttpParams(regexRule!, params);
    const httpUri = this.getHttpUri('vouchers');

    return this.httpClient.get<ApiResponse<Voucher[]>>(httpUri, { params: httpParams });
  }

  loadVoucherOwners(
    params: Params,
    regexRules: RegexRule[],
    excludedRegexValidators: RegexRule[]
  ): Observable<ApiResponse<VoucherOwner[]> | null> {
    const matchsExcludeRegex = this.checkIfExcludedRegexMatches(params.query, excludedRegexValidators);
    if (matchsExcludeRegex) return of(null);

    const regexRule = this.checkIfRegexMatches(params.query, regexRules);
    const httpParams = this.getHttpParams(regexRule!, params);
    const httpUri = this.getHttpUri('voucher_owners');

    return this.httpClient.get<ApiResponse<VoucherOwner[]>>(httpUri, { params: httpParams });
  }

  loadCardOrders(
    params: Params,
    regexRules: RegexRule[],
    excludedRegexValidators: RegexRule[]
  ): Observable<ApiResponse<CardOrder[]> | null> {
    const matchsExcludeRegex = this.checkIfExcludedRegexMatches(params.query, excludedRegexValidators);
    if (matchsExcludeRegex) return of(null);

    const regexRule = this.checkIfRegexMatches(params.query, regexRules);
    const httpParams = this.getHttpParams(regexRule!, params);
    const httpUri = this.getHttpUri('card_orders');

    return this.httpClient.get<ApiResponse<CardOrder[]>>(httpUri, { params: httpParams });
  }

  loadLoadOrders(
    params: Params,
    regexRules: RegexRule[],
    excludedRegexValidators: RegexRule[]
  ): Observable<ApiResponse<LoadOrder[]> | null> {
    const matchsExcludeRegex = this.checkIfExcludedRegexMatches(params.query, excludedRegexValidators);
    if (matchsExcludeRegex) return of(null);

    const regexRule = this.checkIfRegexMatches(params.query, regexRules);
    const httpParams = this.getHttpParams(regexRule!, params);
    const httpUri = this.getHttpUri('load_orders');

    return this.httpClient.get<ApiResponse<LoadOrder[]>>(httpUri, { params: httpParams });
  }

  loadUnloadOrders(
    params: Params,
    regexRules: RegexRule[],
    excludedRegexValidators: RegexRule[]
  ): Observable<ApiResponse<UnloadOrder[]> | null> {
    const matchsExcludeRegex = this.checkIfExcludedRegexMatches(params.query, excludedRegexValidators);
    if (matchsExcludeRegex) return of(null);

    const regexRule = this.checkIfRegexMatches(params.query, regexRules);
    const httpParams = this.getHttpParams(regexRule!, params);
    const httpUri = this.getHttpUri('unload_orders');

    return this.httpClient.get<ApiResponse<UnloadOrder[]>>(httpUri, { params: httpParams });
  }

  loadScheduledLoadOrders(
    params: Params,
    regexRules: RegexRule[],
    excludedRegexValidators: RegexRule[]
  ): Observable<ApiResponse<ScheduledLoadOrder[]> | null> {
    const matchsExcludeRegex = this.checkIfExcludedRegexMatches(params.query, excludedRegexValidators);
    if (matchsExcludeRegex) return of(null);

    const regexRule = this.checkIfRegexMatches(params.query, regexRules);
    const httpParams = this.getHttpParams(regexRule!, params);
    const httpUri = this.getHttpUri('scheduled_load_orders');

    return this.httpClient.get<ApiResponse<ScheduledLoadOrder[]>>(httpUri, { params: httpParams });
  }

  loadInvoices(
    params: Params,
    regexRules: RegexRule[],
    excludedRegexValidators: RegexRule[]
  ): Observable<ApiResponse<Invoice[]> | null> {
    const matchsExcludeRegex = this.checkIfExcludedRegexMatches(params.query, excludedRegexValidators);
    if (matchsExcludeRegex) return of(null);

    const regexRule = this.checkIfRegexMatches(params.query, regexRules);
    const httpParams = this.getHttpParams(regexRule!, params);
    const httpUri = this.getHttpUri('invoices');

    return this.httpClient.get<ApiResponse<Invoice[]>>(httpUri, { params: httpParams });
  }

  loadMore(nextUrl: string): Observable<ApiResponse<any>> {
    return this.httpClient.get<ApiResponse<any>>(nextUrl);
  }

  // --------- Helper functions to prepare API Calls with URI and Params -------------

  private getHttpUri(collection: string): string {
    return this.searchUri.build({ collection });
  }

  private getHttpParams(regexRule: RegexRule, params: Params): HttpParams {
    let httpParams: HttpParams | null;
    if (regexRule.requestType === 'FILTER') {
      httpParams = this.appendFilterParam(params.query, regexRule.key!);
    }
    // will be for the SEARCH request type
    else {
      httpParams = this.appendSearchParam(params.query, regexRule.key!);
    }

    const { sort, direction } = params;
    if (sort && direction) {
      httpParams = this.appendSortParam(httpParams, sort, direction);
    }

    return httpParams;
  }

  private checkIfExcludedRegexMatches(searchParam: string, regexRules: RegexRule[]): RegexRule | undefined {
    for (let index = 0; index < regexRules.length; index++) {
      const rule = regexRules[index]!;

      if (rule.validator!.test(searchParam)) {
        return rule;
      }
    }

    return undefined;
  }

  private checkIfRegexMatches(searchParam: string, regexRules: RegexRule[]): RegexRule | undefined {
    for (let index = 0; index < regexRules.length; index++) {
      const rule = regexRules[index]!;

      if (!rule.default && rule.validator!.test(searchParam)) {
        return rule;
      } else if (index === regexRules.length - 1) {
        return rule;
      }
    }
  }

  private appendFilterParam(searchParam: string, key: string): HttpParams {
    return new HttpParams().set(`filter[${key}]`, "'" + searchParam + "'");
  }

  private appendSearchParam(searchParam: string, key: string): HttpParams {
    return new HttpParams().set(`search[${key}]`, "'" + searchParam + "'");
  }

  private appendSortParam(httpParams: HttpParams, sort: string, direction: string): HttpParams {
    return httpParams.append(`sort[${sort}]`, direction);
  }
}
