import { Injectable, inject } from '@angular/core';
import { Params } from '@angular/router';
import { environment } from '@app/env';
import {
  ApiResponse,
  CardOrder,
  Customer,
  Invoice,
  LoadOrder,
  Multiplier,
  ScheduledLoadOrder,
  UnloadOrder,
  Voucher,
  VoucherOwner,
} from '@givve/ui-kit/models';
import { NotificationService, RegExpValidators } from '@givve/ui-kit/services';
import { Errors } from '@givve/ui-kit/utils';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { TranslateService } from '@ngx-translate/core';
import { MonoTypeOperatorFunction, Observable, pipe, switchMap, tap, withLatestFrom } from 'rxjs';
import { GlobalSearchService } from '../services/global-search.service';

export interface GlobalSearchState {
  customersState: GlobalSearchListState<Customer>;
  multipliersState: GlobalSearchListState<Multiplier>;
  vouchersState: GlobalSearchListState<Voucher>;
  voucherownersState: GlobalSearchListState<VoucherOwner>;
  cardordersState: GlobalSearchListState<CardOrder>;
  loadordersState: GlobalSearchListState<LoadOrder>;
  unloadordersState: GlobalSearchListState<UnloadOrder>;
  scheduledloadordersState: GlobalSearchListState<ScheduledLoadOrder>;
  invoicesState: GlobalSearchListState<Invoice>;
}

export interface GlobalSearchListState<T> {
  routerLink: string;
  type: GlobalSearchType;
  regexRules: RegexRule[];
  excludedRegexValidators: RegexRule[];
  apiResponse: ApiResponse<T[]> | null;
  statusType: StatusType;
}

export interface RegexRule {
  validator?: RegExp;
  key?: string;
  requestType?: 'SEARCH' | 'FILTER';
  default?: boolean;
}

export type StatusType = 'LOADING' | 'DATA' | 'ERROR' | 'INIT' | 'LOAD_MORE';
export type GlobalSearchType =
  | 'customers'
  | 'multipliers'
  | 'vouchers'
  | 'voucherowners'
  | 'cardorders'
  | 'loadorders'
  | 'unloadorders'
  | 'scheduledloadorders'
  | 'invoices';

const CLIENT_NUMBER_DIGITS_LENGTH = environment.production ? 5 : 7;

const excludedRegexRulesCustomerAndMultiplier = [
  {
    validator: RegExpValidators.plInvoice,
  },
  {
    validator: RegExpValidators.voucherToken,
  },
];

const excludedRegexRulesVoucherAndVoucherOwner = [
  {
    validator: RegExpValidators.plInvoice,
  },
  {
    validator: RegExpValidators.number({ length: CLIENT_NUMBER_DIGITS_LENGTH }),
  },
];

const excludedRegexRulesOrder = [
  {
    validator: RegExpValidators.email,
  },
  {
    validator: RegExpValidators.number({ length: CLIENT_NUMBER_DIGITS_LENGTH }),
  },
  {
    validator: RegExpValidators.voucherToken,
  },
];

const regexRulesOrder = [
  // PL number of CO, LO, ULO or Invoice
  {
    key: 'invoice.number',
    validator: RegExpValidators.plInvoice,
    requestType: 'FILTER',
  },
  // mongoDB id of CO, LO or Invoice
  {
    key: 'id',
    requestType: 'FILTER',
    default: true,
  },
] as RegexRule[];

const regexRulesScheduledLoadOrder = [
  // mongoDB id of SLO
  {
    key: 'id',
    requestType: 'FILTER',
    default: true,
  },
] as RegexRule[];

const excludedRegexRulesScheduledLoadOrder = [
  {
    validator: RegExpValidators.email,
  },
  {
    validator: RegExpValidators.plInvoice,
  },
  {
    validator: RegExpValidators.number({ length: CLIENT_NUMBER_DIGITS_LENGTH }),
  },
  {
    validator: RegExpValidators.voucherToken,
  },
];

export const DEFAULT_STATE: GlobalSearchState = {
  customersState: {
    routerLink: 'customers',
    type: 'customers',
    regexRules: [
      // customer number
      {
        key: 'number',
        validator: RegExpValidators.number({ length: CLIENT_NUMBER_DIGITS_LENGTH }),
        requestType: 'FILTER',
      },
      // email address of the company
      {
        key: 'email.raw',
        validator: RegExpValidators.email,
        requestType: 'FILTER',
      },
      // mongoDB id
      {
        key: 'id',
        validator: RegExpValidators.id,
        requestType: 'FILTER',
      },
      // name of the company
      {
        key: 'name',
        requestType: 'SEARCH',
        default: true,
      },
    ],
    excludedRegexValidators: excludedRegexRulesCustomerAndMultiplier,
    apiResponse: null,
    statusType: 'INIT',
  },
  multipliersState: {
    routerLink: 'multipliers',
    type: 'multipliers',
    regexRules: [
      // multiplier number
      {
        key: 'number',
        validator: RegExpValidators.number({ length: CLIENT_NUMBER_DIGITS_LENGTH }),
        requestType: 'FILTER',
      },
      // email address of the mulitplier
      {
        key: 'email.raw',
        validator: RegExpValidators.email,
        requestType: 'FILTER',
      },
      // mongoDB id
      {
        key: 'id',
        validator: RegExpValidators.id,
        requestType: 'FILTER',
      },
      // name of the multiplier
      {
        key: 'name',
        requestType: 'SEARCH',
        default: true,
      },
    ],
    excludedRegexValidators: excludedRegexRulesCustomerAndMultiplier,
    apiResponse: null,
    statusType: 'INIT',
  },
  vouchersState: {
    routerLink: 'vouchers',
    type: 'vouchers',
    regexRules: [
      // voucher token
      {
        key: 'token',
        validator: RegExpValidators.voucherToken,
        requestType: 'FILTER',
      },
      // email address of the voucher owner
      {
        key: 'owner.email.raw',
        validator: RegExpValidators.email,
        requestType: 'FILTER',
      },
      // mongoDB id
      {
        key: 'id',
        validator: RegExpValidators.id,
        requestType: 'FILTER',
      },
      // full name of the voucher owner
      {
        key: 'owner.full_name',
        requestType: 'SEARCH',
        default: true,
      },
    ],
    excludedRegexValidators: excludedRegexRulesVoucherAndVoucherOwner,
    apiResponse: null,
    statusType: 'INIT',
  },
  voucherownersState: {
    routerLink: 'voucher-owners',
    type: 'voucherowners',
    regexRules: [
      // email of the voucher owner
      {
        key: 'email.raw',
        validator: RegExpValidators.email,
        requestType: 'FILTER',
      },
      // mongoDB id
      {
        key: 'id',
        validator: RegExpValidators.id,
        requestType: 'FILTER',
      },
      // full name of the voucher owner
      {
        key: 'full_name',
        requestType: 'SEARCH',
        default: true,
      },
    ],
    excludedRegexValidators: [
      ...excludedRegexRulesVoucherAndVoucherOwner,
      {
        validator: RegExpValidators.voucherToken,
      },
    ],
    apiResponse: null,
    statusType: 'INIT',
  },
  cardordersState: {
    routerLink: 'card-orders',
    type: 'cardorders',
    regexRules: regexRulesOrder,
    excludedRegexValidators: excludedRegexRulesOrder,
    apiResponse: null,
    statusType: 'INIT',
  },
  loadordersState: {
    routerLink: 'load-orders',
    type: 'loadorders',
    regexRules: regexRulesOrder,
    excludedRegexValidators: excludedRegexRulesOrder,
    apiResponse: null,
    statusType: 'INIT',
  },
  unloadordersState: {
    routerLink: 'unload-orders',
    type: 'unloadorders',
    regexRules: regexRulesOrder,
    excludedRegexValidators: excludedRegexRulesOrder,
    apiResponse: null,
    statusType: 'INIT',
  },
  scheduledloadordersState: {
    routerLink: 'scheduled-load-orders',
    type: 'scheduledloadorders',
    regexRules: regexRulesScheduledLoadOrder,
    excludedRegexValidators: excludedRegexRulesScheduledLoadOrder,
    apiResponse: null,
    statusType: 'INIT',
  },
  invoicesState: {
    routerLink: 'invoices',
    type: 'invoices',
    regexRules: [
      // PL number of CO, LO or Invoice
      {
        key: 'number',
        validator: RegExpValidators.plInvoice,
        requestType: 'FILTER',
      },
      // mongoDB id of CO, LO or Invoice
      {
        key: 'id',
        requestType: 'FILTER',
        default: true,
      },
    ],
    excludedRegexValidators: [
      {
        validator: RegExpValidators.email,
      },
      {
        validator: RegExpValidators.number({ length: CLIENT_NUMBER_DIGITS_LENGTH }),
      },
      {
        validator: RegExpValidators.voucherToken,
      },
    ],
    apiResponse: null,
    statusType: 'INIT',
  },
};

@Injectable()
export class GlobalSearchStore extends ComponentStore<GlobalSearchState> {
  private globalSearchService = inject(GlobalSearchService);
  private notification = inject(NotificationService);
  private translate = inject(TranslateService);
  readonly vm$ = this.select((state) => state);
  readonly customerState$ = this.select((state) => state.customersState);
  readonly multiplierState$ = this.select((state) => state.multipliersState);
  readonly voucherState$ = this.select((state) => state.vouchersState);
  readonly voucherOwnerState$ = this.select((state) => state.voucherownersState);
  readonly cardOrderState$ = this.select((state) => state.cardordersState);
  readonly loadOrderState$ = this.select((state) => state.loadordersState);
  readonly unloadOrderState$ = this.select((state) => state.unloadordersState);
  readonly scheduledLoadOrderState$ = this.select((state) => state.scheduledloadordersState);
  readonly invoiceState$ = this.select((state) => state.invoicesState);

  constructor() {
    super(DEFAULT_STATE);
  }

  loadCustomerList = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      tap(() =>
        this.patchState((state) => ({ ...state, customersState: { ...state.customersState, statusType: 'LOADING' } }))
      ),
      withLatestFrom(this.customerState$),
      switchMap(([params, customerState]) =>
        this.globalSearchService
          .loadCustomers(params, customerState.regexRules, customerState.excludedRegexValidators)
          .pipe(this.tapResponseGlobalSearchState<Customer>('customers'))
      )
    );
  });

  loadMultiplierList = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      tap(() =>
        this.patchState((state) => ({
          ...state,
          multipliersState: { ...state.multipliersState, statusType: 'LOADING' },
        }))
      ),
      withLatestFrom(this.multiplierState$),
      switchMap(([params, multiplierState]) =>
        this.globalSearchService
          .loadMultipliers(params, multiplierState.regexRules, multiplierState.excludedRegexValidators)
          .pipe(this.tapResponseGlobalSearchState<Multiplier>('multipliers'))
      )
    );
  });

  loadVoucherList = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      tap(() =>
        this.patchState((state) => ({ ...state, vouchersState: { ...state.vouchersState, statusType: 'LOADING' } }))
      ),
      withLatestFrom(this.voucherState$),
      switchMap(([params, voucherState]) =>
        this.globalSearchService
          .loadVouchers(params, voucherState.regexRules, voucherState.excludedRegexValidators)
          .pipe(this.tapResponseGlobalSearchState<Voucher>('vouchers'))
      )
    );
  });

  loadVoucherOwnerList = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      tap(() =>
        this.patchState((state) => ({
          ...state,
          voucherownersState: { ...state.voucherownersState, statusType: 'LOADING' },
        }))
      ),
      withLatestFrom(this.voucherOwnerState$),
      switchMap(([params, voucherOwnerState]) =>
        this.globalSearchService
          .loadVoucherOwners(params, voucherOwnerState.regexRules, voucherOwnerState.excludedRegexValidators)
          .pipe(this.tapResponseGlobalSearchState<VoucherOwner>('voucherowners'))
      )
    );
  });

  loadCardOrderList = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      tap(() =>
        this.patchState((state) => ({ ...state, cardordersState: { ...state.cardordersState, statusType: 'LOADING' } }))
      ),
      withLatestFrom(this.cardOrderState$),
      switchMap(([params, cardOrderState]) =>
        this.globalSearchService
          .loadCardOrders(params, cardOrderState.regexRules, cardOrderState.excludedRegexValidators)
          .pipe(this.tapResponseGlobalSearchState<CardOrder>('cardorders'))
      )
    );
  });

  loadLoadOrderList = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      tap(() =>
        this.patchState((state) => ({ ...state, loadordersState: { ...state.loadordersState, statusType: 'LOADING' } }))
      ),
      withLatestFrom(this.loadOrderState$),
      switchMap(([params, loadOrderState]) =>
        this.globalSearchService
          .loadLoadOrders(params, loadOrderState.regexRules, loadOrderState.excludedRegexValidators)
          .pipe(this.tapResponseGlobalSearchState<LoadOrder>('loadorders'))
      )
    );
  });

  loadUnloadOrderList = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      tap(() =>
        this.patchState((state) => ({
          ...state,
          unloadordersState: { ...state.unloadordersState, statusType: 'LOADING' },
        }))
      ),
      withLatestFrom(this.unloadOrderState$),
      switchMap(([params, unloadOrderState]) =>
        this.globalSearchService
          .loadUnloadOrders(params, unloadOrderState.regexRules, unloadOrderState.excludedRegexValidators)
          .pipe(this.tapResponseGlobalSearchState<UnloadOrder>('unloadorders'))
      )
    );
  });

  loadScheduledLoadOrderList = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      tap(() =>
        this.patchState((state) => ({
          ...state,
          scheduledloadordersState: { ...state.scheduledloadordersState, statusType: 'LOADING' },
        }))
      ),
      withLatestFrom(this.scheduledLoadOrderState$),
      switchMap(([params, scheduledLoadOrderState]) =>
        this.globalSearchService
          .loadScheduledLoadOrders(
            params,
            scheduledLoadOrderState.regexRules,
            scheduledLoadOrderState.excludedRegexValidators
          )
          .pipe(this.tapResponseGlobalSearchState<ScheduledLoadOrder>('scheduledloadorders'))
      )
    );
  });

  loadInvoiceList = this.effect((params$: Observable<Params>) => {
    return params$.pipe(
      tap(() =>
        this.patchState((state) => ({ ...state, invoicesState: { ...state.invoicesState, statusType: 'LOADING' } }))
      ),
      withLatestFrom(this.invoiceState$),
      switchMap(([params, invoiceState]) =>
        this.globalSearchService
          .loadInvoices(params, invoiceState.regexRules, invoiceState.excludedRegexValidators)
          .pipe(this.tapResponseGlobalSearchState<Invoice>('invoices'))
      )
    );
  });

  loadMoreList = this.effect((listState$: Observable<GlobalSearchListState<any>>) => {
    return listState$.pipe(
      tap((state) => this.setStatusTypeToLoadMoreState(state.type)),
      switchMap((state) =>
        this.globalSearchService.loadMore(state.apiResponse?.links.next!).pipe(this.tapResponseLoadMore(state.type))
      )
    );
  });

  setStatusTypeToLoadMoreState = this.updater((state, type: GlobalSearchType) => {
    return {
      ...state,
      [type + 'State']: { ...state[(type + 'State') as keyof GlobalSearchState], statusType: 'LOAD_MORE' },
    };
  });

  resetState = this.updater((state) => ({
    customersState: { ...state.customersState, apiResponse: null, statusType: 'INIT' },
    multipliersState: { ...state.multipliersState, apiResponse: null, statusType: 'INIT' },
    vouchersState: { ...state.vouchersState, apiResponse: null, statusType: 'INIT' },
    voucherownersState: { ...state.voucherownersState, apiResponse: null, statusType: 'INIT' },
    cardordersState: { ...state.cardordersState, apiResponse: null, statusType: 'INIT' },
    loadordersState: { ...state.loadordersState, apiResponse: null, statusType: 'INIT' },
    unloadordersState: { ...state.unloadordersState, apiResponse: null, statusType: 'INIT' },
    scheduledloadordersState: { ...state.scheduledloadordersState, apiResponse: null, statusType: 'INIT' },
    invoicesState: { ...state.invoicesState, apiResponse: null, statusType: 'INIT' },
  }));

  /*
   * This function is used to update the state of the store after "Load More" got pressed.
   * It is used to update the state (generic way) of the store when the API got called.
   * */
  tapResponseLoadMore<T>(type: GlobalSearchType): MonoTypeOperatorFunction<ApiResponse<T[]>> {
    return pipe(
      tapResponse(
        (apiResponse) => {
          this.patchState((state) => {
            (apiResponse.data as any) = state[(type + 'State') as keyof GlobalSearchState].apiResponse!.data.concat(
              apiResponse.data as any
            );
            return {
              ...state,
              [type + 'State']: {
                ...state[(type + 'State') as keyof GlobalSearchState],
                apiResponse,
                statusType: 'DATA',
              },
            };
          });
        },
        (error: any) => {
          this.notification.open({
            message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
          });

          this.patchState((state) => ({
            ...state,
            [type + 'State']: {
              ...state[(type + 'State') as keyof GlobalSearchState],
              statusType: 'ERROR',
            },
          }));
        }
      )
    );
  }

  /*
   * This function is used to update the state of the store.
   * It is used to update the state (generic way) of the store when the API got called.
   * */
  tapResponseGlobalSearchState<T>(type: GlobalSearchType): MonoTypeOperatorFunction<ApiResponse<T[]> | null> {
    return pipe(
      tapResponse(
        (apiResponse) => {
          // if there is data, then update the state
          if (apiResponse) {
            this.patchState((state) => ({
              ...state,
              [type + 'State']: {
                ...state[(type + 'State') as keyof GlobalSearchState],
                apiResponse,
                statusType: 'DATA',
              },
            }));
          }
          // if there is null data (e.g. regex excluded rule), then we do no need to update apiResponse
          else {
            this.patchState((state) => ({
              ...state,
              [type + 'State']: {
                ...state[(type + 'State') as keyof GlobalSearchState],
                apiResponse: null,
                statusType: 'DATA',
              },
            }));
          }
        },
        (error: any) => {
          this.notification.open({
            message: Errors.getApiErrorMessage(error) || this.translate.instant('common.errors.general'),
          });

          this.patchState((state) => ({
            ...state,
            [type + 'State']: {
              ...state[(type + 'State') as keyof GlobalSearchState],
              statusType: 'ERROR',
            },
          }));
        }
      )
    );
  }
}
